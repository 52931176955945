import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { isRedirectUriAllowed } from "@lib/oauth-query-string-keys";
import Head from "next/head";
import Layout from "@components/Layout";
import { useTranslation } from "react-i18next";

type RedirectUriCheckerProps = {
  children: React.ReactNode;
};

const RedirectUriChecker = ({ children }: RedirectUriCheckerProps) => {
  const router = useRouter();
  const [showChildren, setShowChildren] = useState(true);
  const { t } = useTranslation();

  const { query } = router;
  const { redirect_uri } = query;

  useEffect(() => {
    if (redirect_uri) {
      const redirectUri = redirect_uri as string;

      if (!isRedirectUriAllowed(redirectUri)) {
        setShowChildren(false);
        console.error("redirect_uri not allowed - ", redirectUri);
      }
    }
  }, [redirect_uri]);

  if (!showChildren) {
    return (
      <>
        <Head>
          <title>{t("callback_url_mismatch")}</title>
        </Head>
        <Layout>
          <div className="mb-4 text-2xl font-medium">
            {t("callback_url_mismatch")}
          </div>
          <div className="mb-8 text-gray-300">
            {t("callback_url_not_allowed")}
          </div>
        </Layout>
      </>
    );
  }

  return children;
};

export default RedirectUriChecker;
