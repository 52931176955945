import "../styles/globals.css";
import type { AppProps } from "next/app";
import DataDog from "@components/DataDog";
import i18n from "i18next";
import Head from "@components/Head";
import { VendorProvider } from "@contexts/vendor-context";
import RedirectUriChecker from "@components/RedirectUriChecker";
import { initReactI18next } from "react-i18next";
import enJson from "../locales/en/default.json";
import svJson from "../locales/sv/default.json";
import nbJson from "../locales/nb/default.json";
import itJson from "../locales/it/default.json";
import LanguageDetector from "i18next-browser-languagedetector";
import { useState, useEffect } from "react";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ["querystring", "path"],
      lookupQuerystring: "lang",
    },
    resources: {
      en: { translation: enJson },
      sv: { translation: svJson },
      nb: { translation: nbJson },
      it: { translation: itJson },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

function MyApp({ Component, pageProps }: AppProps) {
  const [isClientSide, setIsClientSide] = useState(false);

  // this might not be needed now have made other fixes!
  useEffect(() => {
    setIsClientSide(true);
  }, []);

  // fix so we only run client-side
  if (!isClientSide) {
    return null;
  }

  return (
    <VendorProvider>
      <Head />
      <DataDog />
      <RedirectUriChecker>
        <Component {...pageProps} />
      </RedirectUriChecker>
    </VendorProvider>
  );
}

export default MyApp;
