import { ParsedUrlQuery } from "querystring";

export function flattenQueryString(query: ParsedUrlQuery) {
  const flattenedQuery: Record<string, string> = {};
  for (const [key, value] of Object.entries(query)) {
    if (Array.isArray(value)) {
      flattenedQuery[key] = value[0];
    } else if (value) {
      flattenedQuery[key] = value;
    }
  }
  return flattenedQuery;
}

export function flattenQueryStringValue(value: string | string[]) {
  if (Array.isArray(value)) {
    return value[0];
  }
  return value;
}

export function flattenQueryStringValuePossiblyNull(value?: string | string[]) {
  if (!value) return value;

  return flattenQueryStringValue(value);
}
