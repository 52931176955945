import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { isCrawler } from "@lib/tracking";
const ERROR_IGNORE_LIST = ["ResizeObserver loop limit exceeded"];

async function initializeDatadog() {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
    datadogRum.init({
      applicationId: "09bfcdcb-36d6-40d2-ac2a-b6c3d43a725b",
      clientToken: "pub6319333b7f4f5a01b0462b92f6fbe2fe",
      site: "datadoghq.eu",
      service: "login2",
      env: "production",
      version: "1.0.0",
      sessionSampleRate: 10,
      sessionReplaySampleRate: 10,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      beforeSend: (event, context) => {
        if (isCrawler(navigator.userAgent)) {
          return false;
        }

        if (
          event.type === "error" &&
          event.error &&
          ERROR_IGNORE_LIST.includes(event.error.message)
        ) {
          return false;
        }

        return true;
      },
    });

    // should we do another datadogRum.setUser() here?
    // we're doign it in a few places with the email address... maybe on /callback we could set it
    // based on a the id_token we receive in the URL...

    datadogLogs.init({
      clientToken: "pub6319333b7f4f5a01b0462b92f6fbe2fe",
      site: "datadoghq.eu",
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: "login2",
      env: "production",
      beforeSend: ({ error, message }) => {
        if (isCrawler(navigator.userAgent)) {
          return false;
        }

        if (error && ERROR_IGNORE_LIST.includes(message)) {
          return false;
        }

        // Don't log any DD RUM errors here in DD logs
        if (message.includes("rum.browser-intake-datadoghq.eu/api/v2/rum")) {
          return false;
        }

        return true;
      },
    });

    datadogRum.startSessionReplayRecording();
  }
}

const User = () => {
  useEffect(() => {
    try {
      initializeDatadog();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, []);

  return null;
};

export default User;
