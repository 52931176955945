import { createContext, useContext, useEffect } from "react";
import { useRouter, NextRouter } from "next/router";
import useSWR from "swr";
import { flattenQueryString } from "@lib/utils";
import { ParsedUrlQuery } from "querystring";
import {
  decodeOauthQueryString,
  getStateFromHashOrQueryParams,
} from "@lib/oauth-query-string-keys";

interface ProviderProps {
  children: React.ReactNode;
}

type StyleType = {
  primaryColor: string;
  buttonTextColor: string;
  primaryHoverColor: string;
};

export type VendorSettings = {
  logoUrl: string;
  loginBackgroundImage?: string;
  style: StyleType;
  supportEmail: string;
  supportUrl: string;
  name: string;
  // this is a hack pending putting Sesamy styles as a vendor style
  showGreyishBackground?: boolean;
  termsAndConditionsUrl?: string;
};

type ServiceSettings = {
  style: StyleType;
};

const SERVICE_STYLES: { [key: string]: ServiceSettings } = {
  spotify: {
    style: {
      // this has a different primary color https://www.spotify.com/fr/signup
      primaryColor: "#3BD44D",
      buttonTextColor: "#000",
      primaryHoverColor: "#1ed760",
    },
  },
};

// Throw this away the day https://api.sesamy.x/profile/vendors/sesamy/style returns an actual sesamy-vendor
const MOCKED_SESAMY_VENDOR = {
  name: "sesamy",
  logoUrl: `https://assets.sesamy.com/static/images/email/sesamy-logo.png`,
  style: {
    primaryColor: "#7D68F4",
    buttonTextColor: "#7D68F4",
    primaryHoverColor: "#7D68F4",
  },
  loginBackgroundImage: "",
  checkoutHideSocial: false,
  supportEmail: "support@sesamy.com",
  supportUrl: "https://support.sesamy.com",
  siteUrl: "https://sesamy.com",
  termsAndConditionsUrl: "https://store.sesamy.com/pages/terms-of-service",
  manageSubscriptionsUrl: "https://account.sesamy.com/manage-subscriptions",
};

function getServiceStyles(serviceId?: string) {
  if (!!serviceId) {
    return SERVICE_STYLES[serviceId];
  }
}

type VendorContext = {
  vendorSettings?: VendorSettings;
  clientId?: string | null;
  vendorId?: string;
  serviceId?: string;
  responseType: string;
  isLoadingVendorSettings: boolean;
};

const VendorContext = createContext<VendorContext>({
  isLoadingVendorSettings: false,
  responseType: "",
});

function getVendorsAPIUrl(vendorId?: string) {
  if (vendorId) {
    return `${process.env.NEXT_PUBLIC_API_URL}/profile/vendors/${vendorId}/style`;
  }
}

function getClientIdFromQuerystring(router: NextRouter) {
  const querystringJson = flattenQueryString(router.query);

  if (querystringJson.client_id) {
    return querystringJson.client_id;
  }

  const state = getStateFromHashOrQueryParams(router);

  if (state) {
    const oauthState = decodeOauthQueryString(state);

    if (oauthState.client_id) {
      return oauthState.client_id;
    }
  }
}

function getVendorIdFromQuerystring(router: NextRouter) {
  const querystringJson = flattenQueryString(router.query);

  if (querystringJson.vendor_id) {
    return querystringJson.vendor_id;
  }

  const state = getStateFromHashOrQueryParams(router);

  if (state) {
    const oauthState = decodeOauthQueryString(state);

    if (oauthState.vendor_id) {
      return oauthState.vendor_id;
    }
  }
}

function getServiceIdFromQuerystring(router: NextRouter) {
  const querystringJson = flattenQueryString(router.query);

  if (querystringJson.service_id) {
    return querystringJson.service_id;
  }

  const state = getStateFromHashOrQueryParams(router);

  if (state) {
    const oauthState = decodeOauthQueryString(state);

    if (oauthState.service_id) {
      return oauthState.service_id;
    }
  }
}

function getResponseTypeFromQuerystring(querystring: ParsedUrlQuery) {
  const querystringJson = flattenQueryString(querystring);

  return querystringJson.response_type;
}

function getCorrectSettings(
  serviceSettings?: ServiceSettings,
  vendorSettings?: VendorSettings,
) {
  if (!vendorSettings) return;

  if (serviceSettings) {
    const settings: VendorSettings = {
      logoUrl: vendorSettings.logoUrl,
      supportEmail: vendorSettings.supportEmail,
      supportUrl: vendorSettings.supportUrl,
      style: serviceSettings.style,
      name: vendorSettings.name,
      // see comment above - pending putting Sesamy styles into vendor styles
      showGreyishBackground: true,
    };

    return settings;
  }

  return vendorSettings;
}

const fetcher = (url: string) => fetch(url).then((r) => r.json());

export const VendorProvider = ({ children }: ProviderProps) => {
  const router = useRouter();

  const clientId = getClientIdFromQuerystring(router);
  const vendorId = getVendorIdFromQuerystring(router);
  const serviceId = getServiceIdFromQuerystring(router);

  const responseType =
    getResponseTypeFromQuerystring(router.query) || "token id_token"; // setting default value here in one place

  const vendorsAPIUrl = getVendorsAPIUrl(vendorId);

  const { data: vendorSettings, isLoading } = useSWR<VendorSettings>(
    !!vendorId && vendorsAPIUrl,
    fetcher,
  );

  const serviceSettings = getServiceStyles(serviceId);

  const { style } = vendorSettings || {};

  useEffect(() => {
    if (serviceSettings) {
      const docStyles = document.documentElement.style;

      const { style: serviceStyles } = serviceSettings;

      docStyles.setProperty("--primary-color", serviceStyles.primaryColor);
      docStyles.setProperty("--primary-hover", serviceStyles.primaryHoverColor);
      docStyles.setProperty("--text-on-primary", serviceStyles.buttonTextColor);
      return;
    }

    if (!style) return;

    const docStyles = document.documentElement.style;
    docStyles.setProperty("--primary-color", style.primaryColor);
    docStyles.setProperty("--primary-hover", style.primaryHoverColor);
    docStyles.setProperty("--text-on-primary", style.buttonTextColor);
  }, [style, serviceSettings]);

  const correctSettings =
    !vendorId || vendorId === "sesamy"
      ? MOCKED_SESAMY_VENDOR
      : getCorrectSettings(serviceSettings, vendorSettings);

  return (
    <VendorContext.Provider
      value={{
        vendorSettings: correctSettings,
        clientId,
        vendorId,
        serviceId,
        responseType,
        isLoadingVendorSettings: isLoading,
      }}
    >
      {children}
    </VendorContext.Provider>
  );
};

export const useVendorContext = () => useContext(VendorContext);
