import { useEffect, useRef, useState } from "react";
import { useVendorContext } from "@contexts/vendor-context";

const darkClassHtml = ({ target: { checked } }: any) =>
  (document.getElementsByTagName("html")[0].className = checked ? "dark" : "");

const DarkModeToggle = () => {
  const { vendorSettings } = useVendorContext();
  const inputRef = useRef<any>();
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    const refRef = inputRef.current;
    refRef.addEventListener("change", darkClassHtml);

    if (vendorSettings?.name !== "sesamy") setDarkMode(false);

    return () => refRef.removeEventListener("change", darkClassHtml);
  }, [vendorSettings]);

  return (
    <div className="fixed right-2 top-2">
      <label htmlFor="dark-mode">
        <div className="flex space-x-2 text-white">
          <input
            type="checkbox"
            name="dark-mode"
            id="dark-mode"
            ref={inputRef}
            defaultChecked={darkMode}
          />
          <span>Dark Mode (vendor = sesamy)</span>
        </div>
      </label>
    </div>
  );
};

export default DarkModeToggle;
