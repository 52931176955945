import Icon from "@components/ui/Icon";
import { useVendorContext } from "@contexts/vendor-context";
import { useState } from "react";

const AppLogo = () => {
  const { vendorSettings } = useVendorContext();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const onImageLoad = () => {
    setIsImageLoaded(true);
  };

  if (vendorSettings?.logoUrl) {
    return (
      <div className="flex h-9 items-center">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={vendorSettings.logoUrl}
          className="max-h-full"
          // do we not have the vendor name?
          alt="Vendor logo"
          data-is-loaded={isImageLoaded}
          onLoad={onImageLoad}
        />
      </div>
    );
  }

  return (
    <div className="mb-9 flex h-9 items-center">
      <Icon className="text-xl text-white" name="sesamy" />
    </div>
  );
};

export default AppLogo;
