import { ParsedUrlQuery } from "querystring";
import { flattenQueryStringValue } from "@lib/utils";
import { NextRouter } from "next/router";

const ALLOWED_REDIRECT_URI_HOSTS = [
  // which do we need?
  "https://token.sesamy.com/callback",
  "https://token.sesamy.dev/callback",
  "https://token.sesamy.com",
  "https://token.sesamy.dev",
  "https://example.com",
  // used on one test
  "https://auth0-js-sdk-login-experiment-login-required-jikl9u790.vercel.sesamy.dev",
];

export const isRedirectUriAllowed = (redirect_uri: string) => {
  try {
    const url = new URL(redirect_uri);

    return ALLOWED_REDIRECT_URI_HOSTS.some((allowedHost) => {
      const allowedHostUrl = new URL(allowedHost);
      if (url.origin !== allowedHostUrl.origin) return false;

      if (url.pathname === allowedHostUrl.pathname) return true;

      if (url.pathname === "/") return true;

      return false;
    });
  } catch (error) {
    return false;
  }
};

// could probably reuse this util elsewhere
function flattenQueryStringKey(value: string | string[]) {
  if (Array.isArray(value)) {
    return value[0];
  }
  return value;
}

export function createOauthQuerystringParams(query: ParsedUrlQuery) {
  const params = new URLSearchParams();

  if (query.redirect_uri) {
    params.append("redirect_uri", flattenQueryStringKey(query.redirect_uri));
  }

  if (query.client_id) {
    params.append("client_id", flattenQueryStringKey(query.client_id));
  }

  if (query.vendor_id) {
    params.append("vendor_id", flattenQueryStringKey(query.vendor_id));
  }

  if (query.service_id) {
    params.append("service_id", flattenQueryStringKey(query.service_id));
  }

  if (query.response_type) {
    params.append("response_type", flattenQueryStringKey(query.response_type));
  }

  if (query.state) {
    params.append("state", flattenQueryStringKey(query.state));
  }

  // this is not a real OAuth key. Doing this fix for Spotify's link flow
  if (query.service_id) {
    params.append("service_id", flattenQueryStringKey(query.service_id));
  }

  if (query.connection) {
    params.append("connection", flattenQueryStringKey(query.connection));
  }

  if (query.auth0Client) {
    params.append("auth0Client", flattenQueryStringKey(query.auth0Client));
  }

  if (query.lang) {
    params.append("lang", flattenQueryStringKey(query.lang));
  }

  if (query.email) {
    params.append("email", flattenQueryStringKey(query.email));
  }

  return params.toString();
}

// TODO - write tests for this - similar to code of Markus' I deleted
export function getRedirectUriWithOAuthQueryStringParams(querystring: string) {
  const params = new URLSearchParams(querystring);

  const redirect_uri = params.get("redirect_uri");

  if (!redirect_uri) {
    throw new Error("redirect_uri is required");
  }

  const url = new URL(redirect_uri);

  const client_id = params.get("client_id");

  if (client_id) {
    url.searchParams.append("client_id", client_id);
  }

  const vendor_id = params.get("vendor_id");

  if (vendor_id) {
    url.searchParams.append("vendor_id", vendor_id);
  }

  const service_id = params.get("service_id");

  if (service_id) {
    url.searchParams.append("service_id", service_id);
  }

  const state = params.get("state");

  if (state) {
    url.searchParams.append("state", state);
  }

  const lang = params.get("lang");

  if (lang) {
    url.searchParams.append("lang", lang);
  }

  return url.href;
}

export function getRedirectUriWithOAuthQueryStringParamsFromParsedUrlQuery(
  query: ParsedUrlQuery,
  accessToken?: string,
  idToken?: string,
  // TODO - could support code or other params here?  Need unit tests really
) {
  const { redirect_uri, client_id, service_id, state, vendor_id, lang } = query;

  if (!redirect_uri) {
    throw new Error("redirect_uri is required");
  }

  const url = new URL(flattenQueryStringKey(redirect_uri));

  if (client_id) {
    url.searchParams.append("client_id", flattenQueryStringKey(client_id));
  }

  if (vendor_id) {
    url.searchParams.append("vendor_id", flattenQueryStringKey(vendor_id));
  }

  if (service_id) {
    url.searchParams.append("service_id", flattenQueryStringKey(service_id));
  }

  if (state) {
    url.searchParams.append("state", flattenQueryStringKey(state));
  }

  if (accessToken) {
    url.searchParams.append("access_token", accessToken);

    if (idToken) {
      url.searchParams.append("id_token", idToken);
    }
  }

  if (lang) {
    url.searchParams.append("lang", flattenQueryStringKey(lang));
  }

  return url.href;
}

type OAuthQueryStringParams = {
  client_id?: string;
  vendor_id?: string;
  redirect_uri?: string;
  service_id?: string;
  response_type?: string;
  state?: string;
  connection?: string;
  auth0Client?: string;
  lang?: string;
};

export function decodeOauthQueryString(querystring: string) {
  const oauthParamsDecoded: OAuthQueryStringParams = {};

  const params = new URLSearchParams(querystring);

  const redirect_uri = params.get("redirect_uri");

  if (redirect_uri) {
    oauthParamsDecoded.redirect_uri = redirect_uri;
  }

  const client_id = params.get("client_id");

  if (client_id) {
    oauthParamsDecoded.client_id = client_id;
  }

  const vendor_id = params.get("vendor_id");

  if (vendor_id) {
    oauthParamsDecoded.vendor_id = vendor_id;
  }

  const service_id = params.get("service_id");

  if (service_id) {
    oauthParamsDecoded.service_id = service_id;
  }

  const response_type = params.get("response_type");
  if (response_type) {
    oauthParamsDecoded.response_type = response_type;
  }

  const state = params.get("state");

  if (state) {
    oauthParamsDecoded.state = state;
  }

  const connection = params.get("connection");

  if (connection) {
    oauthParamsDecoded.connection = connection;
  }

  const auth0Client = params.get("auth0Client");

  if (auth0Client) {
    oauthParamsDecoded.auth0Client = auth0Client;
  }

  const lang = params.get("lang");

  if (lang) {
    oauthParamsDecoded.lang = lang;
  }

  return oauthParamsDecoded;
}

// sometimes this is coming back as the hash part of the url, other times as query string params!
export function getStateFromHashOrQueryParams(router: NextRouter) {
  const hash = router.asPath.split("#")[1];
  const hashParams = new URLSearchParams(hash);
  const state = hashParams.get("state");

  if (state) {
    return decodeURIComponent(state);
  }

  if (router.query.state) {
    return flattenQueryStringValue(router.query.state);
  }
}

export function getAccessTokenFromHashOrQueryParams(router: NextRouter) {
  const hash = router.asPath.split("#")[1];
  const hashParams = new URLSearchParams(hash);
  const accessToken = hashParams.get("access_token");

  if (accessToken) {
    return accessToken;
  }

  if (router.query.access_token) {
    return flattenQueryStringValue(router.query.access_token);
  }
}

export function getIdTokenFromHashOrQueryParams(router: NextRouter) {
  const hash = router.asPath.split("#")[1];
  const hashParams = new URLSearchParams(hash);
  const idToken = hashParams.get("id_token");

  if (idToken) {
    return idToken;
  }

  if (router.query.id_token) {
    return flattenQueryStringValue(router.query.id_token);
  }
}

export function getErrorFromHashOrQueryParams(router: NextRouter) {
  const hash = router.asPath.split("#")[1];
  const hashParams = new URLSearchParams(hash);
  const error = hashParams.get("error");

  if (error) {
    return error;
  }

  if (router.query.error) {
    return flattenQueryStringValue(router.query.error);
  }
}
