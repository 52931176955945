import { VendorSettings } from "@contexts/vendor-context";
import { useTranslation } from "react-i18next";
import Link from "next/link";

const Footer = ({
  vendorSettings,
}: {
  vendorSettings: VendorSettings | undefined;
}) => {
  const { termsAndConditionsUrl, name } = vendorSettings || {};
  const { t } = useTranslation();

  return (
    <div className="mt-8">
      <div className="text-xs text-gray-300">
        {t("agree_to")}{" "}
        <Link
          href={
            termsAndConditionsUrl ||
            "https://store.sesamy.com/pages/terms-of-service"
          }
          className="text-primary hover:underline"
          target="_blank"
        >
          {t("terms")}
        </Link>
        {name === "sesamy" && (
          <>
            {" "}
            {t("and")}{" "}
            <Link
              href={"https://store.sesamy.com/pages/privacy-policy"}
              className="text-primary hover:underline"
              target="_blank"
            >
              {t("privacy_policy")}
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default Footer;
