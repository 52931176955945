import AppLogo from "@components/AppLogo";
import DarkModeToggle from "@components/DarkModeToggle";
import Footer from "@components/Footer";
import { useVendorContext } from "@contexts/vendor-context";
import cn from "classnames";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@components/ui/Icon";
import Link from "next/link";

const DEFAULT_BG = "https://assets.sesamy.com/images/login-bg.jpg";

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const { vendorSettings, isLoadingVendorSettings } = useVendorContext();
  const [isRendered, setIsRendered] = useState(false);

  const { showGreyishBackground } = vendorSettings || {};
  const isSesamy = vendorSettings?.name === "sesamy";
  const { supportUrl } = vendorSettings || {};

  const [bgImgLoaded, setBgImgLoaded] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    document.getElementsByTagName("html")[0].className = isSesamy ? "dark" : "";
  }, [isSesamy]);

  const waitForVendorStyles = !isRendered || isLoadingVendorSettings;

  const bgImg = vendorSettings?.loginBackgroundImage || DEFAULT_BG;

  const inlineStyles = !showGreyishBackground
    ? {
        backgroundImage: `url(${bgImg})`,
      }
    : {};

  useEffect(() => {
    // vendorSettings may be empty initially... probably makes this flakey
    if (waitForVendorStyles) return;

    // this will help with test snapshot stability, so we can only snapshot once the image background has loaded
    var img = new Image();
    img.onload = function () {
      setBgImgLoaded(true);
    };
    img.src = bgImg;
    if (img.complete) {
      setBgImgLoaded(true);
    }
  }, [bgImg, waitForVendorStyles]);

  return (
    <>
      <div
        className={cn(
          "row min-h-full w-full overflow-hidden bg-cover bg-center text-sm sm:bg-fixed sm:bg-left-top",
          {
            "opacity-0": waitForVendorStyles,
            "bg-gray-500": showGreyishBackground,
            "sm:pt-10": isSesamy,
            "sm:pt-16 py-2": !isSesamy,
          },
        )}
        style={inlineStyles}
        data-is-bg-loaded={bgImgLoaded}
      >
        <div
          className={cn(
            "row-up-left w-[calc(100%-theme(space.2)-theme(space.2))] max-w-[1295px] !flex-nowrap sm:w-[calc(100%-theme(space.16)-theme(space.16))]",
          )}
        >
          <div className="column-left w-full sm:w-auto">
            {isSesamy && (
              <div className="w-full pb-4 pt-6 text-center sm:text-left">
                <Icon
                  className="text-2xl text-white md:text-2xl"
                  name="sesamy"
                />
              </div>
            )}
            <div
              className={cn(
                "relative flex w-full flex-col rounded-2xl bg-white px-5 py-10 dark:bg-gray-800 dark:text-white sm:min-h-[700px] sm:max-w-md sm:px-14 sm:py-14 md:min-w-[448px] short:min-h-[558px]",
                {
                  "min-h-[calc(100vh-83px)]": !isSesamy,
                  "min-h-[calc(100vh-148px)]": isSesamy,
                },
              )}
            >
              {!isSesamy && (
                <div className="mb-16">
                  <AppLogo />
                </div>
              )}
              <div className="flex flex-1 flex-col">
                {children}
                <Footer vendorSettings={vendorSettings} />
              </div>
            </div>

            <div
              className={cn("flex w-full items-center px-6 pb-8 pt-4", {
                "justify-center sm:justify-start": isSesamy,
                "justify-between": !isSesamy,
              })}
            >
              {!isSesamy && (
                <div className="flex justify-center leading-[0]">
                  <Link href="https://sesamy.com" target="_blank">
                    <Icon name="sesamy" className="text-xl text-white" />
                  </Link>
                </div>
              )}

              <div className="flex justify-center space-x-2 text-xs text-white sm:justify-normal md:text-xs">
                <a
                  className="text-xs text-white hover:underline md:text-xs"
                  href={supportUrl}
                >
                  {t("contact_support")}
                </a>
                <span className="text-gray-300">|</span>{" "}
                <span>{t("copyright_sesamy")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {process.env.NODE_ENV === "development" && <DarkModeToggle />}
    </>
  );
};

export default Layout;
