import { FC, useState, useEffect } from "react";
import NextHead from "next/head";

const Head: FC<React.PropsWithChildren<unknown>> = () => {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const getMql = () =>
      window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)");
    const mql = getMql();
    const getBrowserTheme = () => {
      return mql && mql.matches ? "dark" : "light";
    };

    const setColorTheme = () => {
      const browserTheme = getBrowserTheme();
      if (browserTheme !== theme) {
        setTheme(browserTheme);
      }
    };

    setColorTheme();

    if (mql?.addEventListener) {
      mql && mql.addEventListener("change", setColorTheme);
      return () => {
        mql && mql.removeEventListener("change", setColorTheme);
      };
    }
  });

  return (
    <>
      <NextHead>
        <link
          rel="preload"
          href="https://assets.sesamy.com/fonts/khteka/WOFF2/KHTeka-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="https://assets.sesamy.com/fonts/khteka/WOFF2/KHTeka-Medium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="https://assets.sesamy.com/fonts/khteka/WOFF2/KHTeka-Bold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/apple-touch-icon.png"
        />
        <link
          rel="mask-icon"
          href="/favicons/safari-pinned-tab.svg"
          color="#000000"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/favicons/favicon-32x32-${
            theme === "dark" ? "light" : "dark"
          }.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`/favicons/favicon-16x16-${
            theme === "dark" ? "light" : "dark"
          }.png`}
        />
        <meta name="theme-color" content="#000000" />
      </NextHead>
    </>
  );
};

export default Head;
